import React from 'react';
import Logo from './pfeifferSvg';
import LogoColored from './pfeifferColored';

//shown storybook controls
export interface IProps {
    opacity?: number,
    width?: number,
    color?: any,
    mono: boolean,
}

const PfeifferLogo = (props: IProps) => {
    const {opacity, width, color, mono} = props;

    if (!mono) return <LogoColored width={width} fillOpacity={opacity}/>

    return <Logo width={width}  fillOpacity={opacity} fill={color}/>;
};

PfeifferLogo.defaultProps = {
    mono: false,
    opacity: 100,
    width: 256,
    color: '',
};

export default PfeifferLogo;