// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import './index.css';
import Button from "../Button"
import _ from "lodash";

type Props = {
    env?: string | 'testing' | 'development' | 'production',
    cnIcpData?: any
}

const devWebportalBaseUrl = "https://www.dev.customerportal.pfeiffer-vacuum.com"
const uatWebportalBaseUrl = "https://www.uat.customerportal.pfeiffer-vacuum.com"
const prodWebportalBaseUrl = "https://www.pfeiffer-vacuum.com"

const FooterMenu = ({env, cnIcpData}: Props) => {
    const {t, i18n} = useTranslation();

    let myAccountURL = `${prodWebportalBaseUrl}/global/${i18n.language}/shop/my-account/`;
    let contactURL = `${prodWebportalBaseUrl}/global/${i18n.language}/contact/`;

    let companyURL = `https://group.pfeiffer-vacuum.com/${i18n.language}/`;
    let careerUrl = i18n.language === "de" ? `https://group.pfeiffer-vacuum.com/de/karriere/` : `https://group.pfeiffer-vacuum.com/en/careers/`;
    let pressUrl = i18n.language === "de" ? `https://group.pfeiffer-vacuum.com/de/presse/` : `https://group.pfeiffer-vacuum.com/en/press/`;
    let investorRelationsUrl = i18n.language === "de" ? `https://ir.pfeiffer-vacuum.com/German/home` : `https://ir.pfeiffer-vacuum.com/`;

    let allProductsURL = `${prodWebportalBaseUrl}/global/${i18n.language}/shop/categories/`;

    if(env && (env === 'testing') || (env === 'development')){
        myAccountURL = `${devWebportalBaseUrl}/global/${i18n.language}/shop/my-account/`;
        contactURL = `${devWebportalBaseUrl}/global/${i18n.language}/contact/`
        allProductsURL = `${devWebportalBaseUrl}/global/${i18n.language}/shop/categories/`;
    }
    if(env && env === 'staging'){
        myAccountURL = `${uatWebportalBaseUrl}/global/${i18n.language}/shop/my-account/`;
        contactURL = `${uatWebportalBaseUrl}/global/${i18n.language}/contact/`
        allProductsURL = `${uatWebportalBaseUrl}/global/${i18n.language}/shop/categories/`;
    }

    return <div className={`full-width flex-row pvFooterMenu`}>
        {cnIcpData &&
            <div className={"flex-col pvFooterMenuItemsWrapper"}>
                <a target="_BLANK" href={_.get(cnIcpData, 'icp.url')}>{_.get(cnIcpData, 'icp.number')}</a>
                <a target="_BLANK" href={_.get(cnIcpData, 'psb.url')}>{_.get(cnIcpData, 'psb.icon')}{_.get(cnIcpData, 'psb.number')}</a>
            </div>
        }

        <div className={"flex-col pvFooterMenuItemsWrapper"}>
            <span className={'pvFooterMenuHeader'}>{t('Help')}</span>

            <a href={myAccountURL}
               target={"_BLANK"}
            >
                {t('My account')}
            </a>
            <a href={contactURL}
               target={"_BLANK"}
            >
                {t('Contact')}
            </a>
        </div>

        <div className={"flex-col pvFooterMenuItemsWrapper"}>
            <span className={'pvFooterMenuHeader'}>{t('Group')}</span>

            <a href={companyURL}
               target={"_BLANK"}
            >
                {t('Company')}
            </a>
            <a href={careerUrl}
               target={"_BLANK"}
            >
                {t('Careers')}
            </a>
            <a href={pressUrl}
               target={"_BLANK"}
            >
                {t('Press')}
            </a>
            <a href={investorRelationsUrl}
               target={"_BLANK"}
            >
                {t('Investor relations')}
            </a>
        </div>

        <div className={'pvFooterMenuProductButtonWrapper'}>
            <Button
                type={'primary'}
                onClick={() => window.open(allProductsURL, '_blank')}
                icon={"arrow_forward"}
            >
                {t('Show all products')}
            </Button>
        </div>
    </div>
};

export default FooterMenu
