import React from "react";
import Loader from "../Loader";
import PfeifferLogo from "../PfeifferLogo";

export default () => {
    return <div style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        textAlign: 'center',
        display: "flex",
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px 0'
    }}>
        <PfeifferLogo />
        <Loader />
    </div>
}
