// @flow
import React from "react";
import FooterInfo, {Version} from "../FooterInfo";
import './index.css';
import FooterMenu from "../FooterMenu";

type Props = {
    env?: string | 'development' | 'testing' | 'staging' | 'production'
    className?: string,
    onChangeLanguage?: (language: string) => void,
    dataProtectionLink?: string,
    onPrivacySettings?: any,
    version?: Version,
    cnIcpData?: any
}

const Footer = ({onChangeLanguage, className, env, dataProtectionLink, onPrivacySettings, version, cnIcpData}: Props) => {

    return <div className={`full-width flex-col pvFooter`}>

        <div className={className}>
            <FooterMenu env={env} cnIcpData={cnIcpData}/>
        </div>

        <FooterInfo
            className={className}
            onChangeLanguage={onChangeLanguage}
            dataProtectionLink={dataProtectionLink}
            onPrivacySettings={onPrivacySettings}
            version={version}
            env={env}
        />
    </div>
};

export default Footer
