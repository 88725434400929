import React from 'react';
import {Spin} from "antd";
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import * as styles from './index.css';

type Props = {
  spinning?: boolean,
  mask?: boolean,
  fullScreen?: boolean,
  size?: string,
  absolute?: boolean,
  loaderText?: string
}

const Loader = ({ spinning, fullScreen , size, absolute, mask, loaderText }: Props) => {
  const {t} = useTranslation();

  if (size === 'small'){
    return <Spin size={'small'} className={classNames('loader', {
      mask,
      // @ts-ignore
      [styles.absolute]: absolute,
    })}/>
  }

  return (
      <div
          className={classNames('loader large', {
            mask,
            // @ts-ignore
            [styles.absolute]: absolute,
            // @ts-ignore
            [styles.hidden]: !spinning,
            // @ts-ignore
            [styles.fullScreen]: fullScreen,
          })}
      >
        <div className={'wrapper'}>
          <div className={'inner'} />
          <div className={'text'}>{loaderText ? loaderText : t('Loading')}</div>
        </div>
      </div>
  );
};

Loader.defaultProps = {
  spinning: true,
  fullScreen: false
};

export default Loader;
