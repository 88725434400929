import * as React from "react"

function SendIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            style={{
                width: 24,
                height: 24,
            }}
            viewBox="0 0 24 24"
            {...props}
        >
            <path fill="currentColor" d="M2 21l21-9L2 3v7l15 2-15 2v7z"/>
        </svg>
    )
}

export default SendIcon
