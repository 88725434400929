import React from 'react';
import './index.css';

export interface Props {
    productName: string,
    productCategory: string,
}

const ProductCardLabel = (props: Props) => {

    const {productName, productCategory} = props;

    return (
        <div className={'product-card-label-wrapper'}>
            <div className={'product-card-label-category'}>
                {productCategory}
            </div>
            <div className={'product-card-label-name'}>
                {productName}
            </div>
        </div>
    );
}


export default ProductCardLabel;
