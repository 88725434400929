// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Divider} from "antd";
import './index.css';

export type Version = {
    feVersion?: string,
    beVersion?: string
}

type Props = {
    className?: string,
    onChangeLanguage?: (language: string) => void,
    dataProtectionLink?: string,
    onPrivacySettings?: any,
    version?: Version,
    env?: string | 'testing' | 'development' | 'production'
}

const devWebportalBaseUrl = "https://www.dev.customerportal.pfeiffer-vacuum.com"
const uatWebportalBaseUrl = "https://www.uat.customerportal.pfeiffer-vacuum.com"
const prodWebportalBaseUrl = "https://www.pfeiffer-vacuum.com"

const FooterInfo = ({onChangeLanguage, className, dataProtectionLink, onPrivacySettings, version, env}: Props) => {

    const {t, i18n} = useTranslation();

    const legalNoticePath = `/global/${i18n.language}/legal-notice/`
    let legalNoticeURL = prodWebportalBaseUrl + legalNoticePath;

    if(env && (env === 'testing') || (env === 'development')){
        legalNoticeURL = devWebportalBaseUrl + legalNoticePath;
    }
    if(env && env === 'staging'){
        legalNoticeURL = uatWebportalBaseUrl + legalNoticePath;
    }

    const companyText = `© ${new Date().getFullYear()} Pfeiffer Vacuum GmbH`

    return <div className={`full-width pvFooterInfoWrapper`}>
        <div className={`full-width flex-row pvFooterInfo ${className}`}>
            <div className={"pvFooterInfoCompany flex-col"}>
                <span className={'company-text'}>{companyText}</span>
                {
                    version?.beVersion || version?.feVersion ?
                        <div className={'flex-row version-label'}>
                            <span className={'version-text'}>Version: </span>
                            <span className={'version-text'}>{version?.beVersion ? `${version?.beVersion} (BE)` : ''}</span>
                            <span className={'version-text'}>{version?.beVersion && version?.feVersion ? ' / ' : ''}</span>
                            <span className={'version-text'}>{version?.feVersion ? `${version?.feVersion} (FE)` : ''}</span>
                        </div>
                    : null
                }
            </div>

            <div className={"flex-row flex-grow-1 flex-align-items-center pvFooterInfoLinks"}>
                <a href={dataProtectionLink}
                   target={"_BLANK"}
                >
                    {t('Data Protection')}
                </a>
                <Divider type="vertical" className={'pvFooterDivider'}/>
                <a href={legalNoticeURL}
                   target={"_BLANK"}
                >
                    {t('Legal Notice')}
                </a>
                {
                    onPrivacySettings ?
                        <>
                            <Divider type="vertical" className={'pvFooterDivider'}/>
                            <a href="#" onClick={onPrivacySettings}>
                                {t('Privacy Settings')}
                            </a>
                        </>
                    : null
                }
            </div>

            {
                onChangeLanguage
                    ? <div className={"flex-row flex-align-items-center pvFooterInfoLanguage"}>
                        <Button type={'link'}
                                onClick={() => onChangeLanguage('en')}
                        >
                            English
                        </Button>
                        <Divider type="vertical" className={'pvFooterDivider'}/>
                        <Button type={'link'}
                                onClick={() => onChangeLanguage('de')}
                        >
                            Deutsch
                        </Button>
                    </div>
                    : null
            }

        </div>
    </div>
};

export default FooterInfo
