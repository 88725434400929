import React from "react";
import {Dropdown} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import Logo from "../Logo";
import HeaderMenu from "./menu";
import './index.css';

interface Props {
    onChangeLanguage?: (language: string) => void,
    onLogoClick?: Function,
    authenticated?: boolean,
    UserButtonType?: 'default' | 'primary' | 'link',
    onLogin?: Function,
    userName?: string,
    onLogout?: Function,
    salesEngineer?: {
        name: string,
        phone: string
    },
    onContact?: () => void,
    old?: boolean,
    env?: string | 'testing' | 'development' | 'production'
}

const Header = ({onChangeLanguage, old, onLogoClick, onLogout, authenticated, onLogin, userName, UserButtonType, salesEngineer, onContact, env}: Props) => {
    const windowWidth = window.innerWidth;

    return <div className={`full-width flex-row pvHeader`}>

        <Logo onLogoClick={onLogoClick} old={old}/>

        {
            windowWidth <= 680
                ? <div className={'HeaderRightMenu'}>
                    <Dropdown
                        overlay={<HeaderMenu
                            mode={'inline'}
                            onChangeLanguage={onChangeLanguage}
                            UserButtonType={UserButtonType}
                            onLogin={onLogin}
                            userName={userName}
                            authenticated={authenticated}
                            onLogout={onLogout}
                            onContact={onContact}
                            env={env}
                        />}
                    >
                        <MenuOutlined
                            id={'LanguageButton'}
                            className={'header-mobile-menu'}
                        />
                    </Dropdown>
                </div>
                : <HeaderMenu
                    mode={'horizontal'}
                    onChangeLanguage={onChangeLanguage}
                    UserButtonType={UserButtonType}
                    onLogin={onLogin}
                    userName={userName}
                    authenticated={authenticated}
                    onLogout={onLogout}
                    className={'HeaderRightMenu'}
                    salesEngineer={salesEngineer}
                    onContact={onContact}
                    env={env}
                />
        }
    </div>
};

export default Header;
