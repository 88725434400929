import React from 'react';
import './index.css';
import ProductCardFilter from "../ProductCardFilter";

export interface Props {
    filters: {
      label: string,
      value: string,
      highlighted?: boolean,
    }[],
}

const ProductCardFilterWrapper = (props: Props) => {

    const {filters} = props;

    return (
        <div className={'product-card-filter-wrapper'}>
            {filters && filters.map((filter, index)=>
                <div className={'product-card-filter-wrapper-item'} key={index}>
                    <ProductCardFilter label={filter.label} value={filter.value} highlighted={filter.highlighted}/>
                </div>
            )}
        </div>
    );
}


export default ProductCardFilterWrapper;
