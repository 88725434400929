import * as React from "react";

function SvgPfeifferLogoMono(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 576 183.8298">
                <path className="a"
                      d="M342.8239,91.8946l-68.9806,3.2718c.0267-1.1036.048-2.2162.048-3.3326q0-1.617-.0437-3.2092l68.9763,3.27"/>
                <path className="a" d="M331.4177,100.2788l-57.974,2.1441c.1784-1.4006.33-5.0594.4-6.5039l57.5744,4.36"/>
                <path className="a"
                      d="M321.6362,106.2751l-49.0012,3.17c.3-2.0533.5524-4.1514.7436-6.2876l48.2576,3.1179"/>
                <path className="a" d="M308.0537,113.7159l-36.7562,3.0966q.6441-2.9686,1.142-6.0964l35.6142,3"/>
                <path className="a" d="M295,121.0252l-25.5849,3.04c.579-1.899,1.1-3.8609,1.5779-5.89L295,121.0252"/>
                <path className="a"
                      d="M284.1546,128.1359l-17.2852,3.12c.7606-1.86,1.4775-3.8135,2.1291-5.855l15.1561,2.7349"/>
                <path className="a"
                      d="M271.7879,134.8951l-8.5337,3.76a60.0651,60.0651,0,0,0,3.0895-6.1587l5.4442,2.3983"/>
                <path className="a"
                      d="M331.4177,84.57l-57.5916,3.1879c-.0693-2.1644-.1955-4.3-.3738-6.3944L331.4177,84.57"/>
                <path className="a"
                      d="M321.6362,77.51l-48.2448,3.12c-.1912-2.1357-.4434-4.2322-.7382-6.2876l48.983,3.168"/>
                <path className="a"
                      d="M308.0537,70.07,272.462,73.0689c-.3259-2.0816-.7084-4.1151-1.1346-6.09L308.0537,70.07"/>
                <path className="a" d="M295,62.76l-23.9814,2.8481q-.7035-3.0373-1.56-5.8831L295,62.76"/>
                <path className="a" d="M284.1546,55.6485l-15.1177,2.7291q-.9711-3.0512-2.1152-5.8384l17.2329,3.1093"/>
                <path className="a" d="M271.7879,48.89l-5.4,2.3807a59.6222,59.6222,0,0,0-3.0334-6.0963l8.4338,3.7156"/>
                <polyline className="a"
                          points="377.513 78.767 361.7 106.725 361.613 106.725 357.894 78.767 353.357 78.767 357.959 111.483 362.943 111.483 382.04 78.767 377.513 78.767"/>
                <path className="b"
                      d="M380.0716,111.4832l5.37-8.5172h15.1785l1.6987,8.5172h4.9366l-6.1834-32.7138h-4.849l-20.8182,32.7138h4.6664m17.4071-28.229,2.5329,15.8141H387.819Z"/>
                <path className="a"
                      d="M442.6862,87.1973c.7607-5.8447-3.2882-8.9274-9.5463-9.1095-10.8978-.3152-16.4424,9.02-18.1677,17.0375-1.9721,9.1531.1783,17.0373,9.8337,17.0373,6.3875,0,12.6969-3.6259,14.3314-9.1073h-4.7582c-1.2948,3.4869-4.8416,5.21-8.3307,5.21-7.1128,0-7.7773-6.3895-6.3222-13.14,1.2435-5.7529,4.3245-13.14,12.3923-13.14,3.35,0,6.2582,2.0837,5.8106,5.2123h4.7571"/>
                <path className="a"
                      d="M479.9777,78.7672H475.45L470.9611,99.61c-1.3513,5.6209-5.3532,8.6559-10.52,8.6559s-7.8648-3.035-6.7923-8.6559l4.489-20.8423h-4.5274l-4.2583,19.7585c-1.9507,8.4273,1.3685,13.6369,10.25,13.6369,8.8776,0,14.4392-5.21,16.1207-13.6369l4.2541-19.7585"/>
                <path className="a"
                      d="M515.7778,78.7672H511.25L506.7613,99.61c-1.35,5.6209-5.3523,8.6559-10.52,8.6559s-7.8692-3.035-6.7912-8.6559l4.489-20.8423h-4.5275l-4.2593,19.7585c-1.9465,8.4273,1.3728,13.6369,10.2461,13.6369,8.8819,0,14.4435-5.21,16.1251-13.6369l4.254-19.7585"/>
                <polyline className="a"
                          points="548.915 111.487 553.443 111.487 560.491 78.774 552.926 78.774 537.874 105.734 537.787 105.734 534.168 78.774 526.059 78.774 519.011 111.487 523.543 111.487 529.883 82.035 529.979 82.035 534.098 111.487 538.356 111.487 555.168 82.035 555.259 82.035 548.915 111.487"/>
                <path className="b"
                      d="M23.7266,111.2545v-12.92h5.2576c7.4781-.2328,11.6147-4.2326,11.6147-10.32,0-8.5059-6.2747-10.2729-13.7133-10.3226H16.5219v33.5625h7.2047m0-27.9833c3.9976.187,9.2077-1.21,9.2077,4.7434,0,5.9483-5.21,4.5531-9.2077,4.74Z"/>
                <polyline className="a"
                          points="55.86 111.255 55.86 96.937 69.339 96.937 69.339 91.36 55.86 91.36 55.86 83.271 70.316 83.271 70.316 77.692 48.655 77.692 48.655 111.255 55.86 111.255"/>
                <polyline className="a"
                          points="79.976 111.255 102.984 111.255 102.984 105.675 87.176 105.675 87.176 96.705 101.498 96.705 101.498 91.13 87.176 91.13 87.176 83.271 102.471 83.271 102.471 77.692 79.976 77.692 79.976 111.255"/>
                <rect className="a" x="114.1204" y="77.692" width="7.2047" height="33.5626"/>
                <polyline className="a"
                          points="140.227 111.255 140.227 96.937 153.701 96.937 153.701 91.36 140.227 91.36 140.227 83.271 154.684 83.271 154.684 77.692 133.023 77.692 133.023 111.255 140.227 111.255"/>
                <polyline className="a"
                          points="171.387 111.255 171.387 96.937 184.866 96.937 184.866 91.36 171.387 91.36 171.387 83.271 185.843 83.271 185.843 77.692 164.182 77.692 164.182 111.255 171.387 111.255"/>
                <polyline className="a"
                          points="195.047 111.255 218.055 111.255 218.055 105.675 202.251 105.675 202.251 96.705 216.569 96.705 216.569 91.13 202.251 91.13 202.251 83.271 217.547 83.271 217.547 77.692 195.047 77.692 195.047 111.255"/>
                <path className="b"
                      d="M235.6357,83.2712h6.044c3.159.046,5.0189,1.2991,5.0189,4.276s-1.86,4.5558-5.0189,4.6018h-6.044V83.2712m0,14.4564h7.6213c2.3727.094,2.8422,2.6488,3.0244,4.5083.3216,3.0243.6522,6.0466,1.2516,9.0186h7.9513c-1.3465-1.86-1.6291-5.8116-1.9507-7.9472-.6084-4.6492-.6084-8.0432-6.1353-8.6489v-.09c4.1365-.8841,6.97-3.769,6.97-8.1385,0-6.8286-5.905-8.6885-11.6669-8.7382h-14.27v33.5625h7.2046Z"/>
        </svg>

    );
}

export default SvgPfeifferLogoMono;