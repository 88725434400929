import * as React from "react";

function SvgPfeifferLogoColored(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props}  viewBox="0 0 651.9678 123.8936">

                <path fill={'#575756'}
                      d="M390.0266,62.4942l-81.331,3.8576c.0315-1.3012.0566-2.613.0566-3.9293q0-1.9065-.0516-3.7838l81.326,3.8555"/>
                <path fill={'#575756'}
                      d="M376.5782,71.1242l-68.3538,3.7833c.21-2.4714.3591-4.99.4409-7.5387l67.9129,3.7554"/>
                <path fill={'#575756'}
                      d="M365.0455,79.4494,307.271,83.1867c.3539-2.421.6512-4.8948.8767-7.4134l56.8978,3.6761"/>
                <path fill={'#575756'} d="M349.0311,88.2225,305.694,91.8734q.7595-3.5,1.3465-7.1879l41.9906,3.537"/>
                <path fill={'#575756'}
                      d="M333.64,96.8405l-30.1657,3.5836c.6827-2.239,1.2974-4.5522,1.86-6.9448L333.64,96.8405"/>
                <path fill={'#575756'}
                      d="M320.8531,105.2242l-20.38,3.6787c.8968-2.1929,1.742-4.4962,2.51-6.9032l17.87,3.2245"/>
                <path fill={'#575756'}
                      d="M306.2722,113.1936l-10.0616,4.4337a70.81,70.81,0,0,0,3.6427-7.2614l6.4189,2.8277"/>
                <path fill={'#575756'}
                      d="M376.5782,53.8586l-67.9028,3.7586c-.0818-2.5519-.2305-5.07-.4408-7.5392l68.3436,3.7806"/>
                <path fill={'#575756'}
                      d="M365.0455,45.534l-56.8827,3.678c-.2255-2.5179-.5228-4.99-.87-7.4133l57.7531,3.7353"/>
                <path fill={'#575756'}
                      d="M349.0311,36.7623,307.067,40.2979c-.3843-2.4543-.8352-4.8519-1.3377-7.1808l43.3018,3.6452"/>
                <path fill={'#575756'} d="M333.64,28.143l-28.2751,3.358q-.8295-3.5811-1.8389-6.9365L333.64,28.143"/>
                <path fill={'#575756'} d="M320.8531,19.7586l-17.8244,3.2177q-1.145-3.5974-2.494-6.8837l20.3184,3.666"/>
                <path fill={'#575756'}
                      d="M306.2722,11.79l-6.3673,2.807a70.2857,70.2857,0,0,0-3.5765-7.1879l9.9438,4.3809"/>
                <polyline fill={'#1d1d1b'}
                          points="430.926 47.017 412.282 79.98 412.18 79.98 407.794 47.017 402.446 47.017 407.871 85.59 413.748 85.59 436.265 47.017 430.926 47.017"/>
                <path fill={'#1d1d1b'}
                      d="M433.9431,85.59l6.3319-10.0421h17.8962L460.174,85.59h5.8205L458.704,47.0191h-5.7172L428.4413,85.59h5.5018m20.5237-33.2832,2.9864,18.6455H443.0777Z"/>
                <path fill={'#1d1d1b'}
                      d="M507.7684,56.9559c.8969-6.8912-3.877-10.5257-11.2556-10.74-12.8489-.3716-19.3862,10.6353-21.42,20.0879-2.3252,10.792.21,20.0878,11.5944,20.0878,7.5311,0,14.97-4.2751,16.8973-10.738h-5.61c-1.5266,4.1113-5.7084,6.143-9.8221,6.143-8.3864,0-9.17-7.5335-7.4542-15.4928,1.4661-6.7828,5.0987-15.4929,14.611-15.4929,3.95,0,7.3787,2.4568,6.8509,6.1455h5.6089"/>
                <path fill={'#1d1d1b'}
                      d="M551.7365,47.0165h-5.3381L541.1057,71.59c-1.5933,6.6273-6.3117,10.2057-12.4031,10.2057s-9.273-3.5784-8.0084-10.2057l5.2927-24.5739h-5.338l-5.0207,23.296c-2.3,9.9362,1.6135,16.0786,12.0856,16.0786,10.4671,0,17.0245-6.1424,19.0071-16.0786l5.0156-23.296"/>
                <path fill={'#1d1d1b'}
                      d="M593.9463,47.0165h-5.3381L583.3155,71.59c-1.5922,6.6273-6.3106,10.2057-12.4031,10.2057s-9.2781-3.5784-8.0071-10.2057l5.2927-24.5739H562.86l-5.0219,23.296c-2.295,9.9362,1.6186,16.0786,12.0806,16.0786,10.4721,0,17.0295-6.1424,19.0121-16.0786l5.0156-23.296"/>
                <polyline fill={'#1d1d1b'}
                          points="633.016 85.595 638.355 85.595 646.665 47.024 637.746 47.024 619.998 78.811 619.896 78.811 615.629 47.024 606.068 47.024 597.758 85.595 603.102 85.595 610.577 50.87 610.69 50.87 615.547 85.595 620.568 85.595 640.39 50.87 640.497 50.87 633.016 85.595"/>
                <path fill={'#d80337'}
                      d="M13.7977,85.32V70.087h6.199c8.817-.2745,13.6942-4.99,13.6942-12.1675,0-10.0287-7.3981-12.1122-16.1686-12.1708H5.303V85.32h8.4947m0-32.9935c4.7134.22,10.8562-1.427,10.8562,5.5926,0,7.0134-6.1428,5.3684-10.8562,5.5888Z"/>
                <polyline fill={'#d80337'}
                          points="51.684 85.32 51.684 68.439 67.576 68.439 67.576 61.864 51.684 61.864 51.684 52.327 68.729 52.327 68.729 45.749 43.189 45.749 43.189 85.32 51.684 85.32"/>
                <polyline fill={'#d80337'}
                          points="80.118 85.32 107.245 85.32 107.245 78.742 88.607 78.742 88.607 68.166 105.493 68.166 105.493 61.592 88.607 61.592 88.607 52.327 106.641 52.327 106.641 45.749 80.118 45.749 80.118 85.32"/>
                <rect fill={'#d80337'}   x="120.3757" y="45.7487" width="8.4947" height="39.5717"/>
                <polyline fill={'#d80337'}
                          points="151.156 85.32 151.156 68.439 167.043 68.439 167.043 61.864 151.156 61.864 151.156 52.327 168.201 52.327 168.201 45.749 142.662 45.749 142.662 85.32 151.156 85.32"/>
                <polyline fill={'#d80337'}
                          points="187.895 85.32 187.895 68.439 203.788 68.439 203.788 61.864 187.895 61.864 187.895 52.327 204.94 52.327 204.94 45.749 179.401 45.749 179.401 85.32 187.895 85.32"/>
                <polyline fill={'#d80337'}
                          points="215.792 85.32 242.919 85.32 242.919 78.742 224.285 78.742 224.285 68.166 241.166 68.166 241.166 61.592 224.285 61.592 224.285 52.327 242.32 52.327 242.32 45.749 215.792 45.749 215.792 85.32"/>
                <path fill={'#d80337'}
                      d="M263.6473,52.3269h7.1261c3.7246.0542,5.9175,1.5317,5.9175,5.0415,0,3.5118-2.1929,5.3715-5.9175,5.4257h-7.1261V52.3269m0,17.0447h8.9859c2.7974.1108,3.351,3.123,3.5658,5.3155.3792,3.5658.769,7.1291,1.4756,10.6333h9.375c-1.5876-2.193-1.9208-6.8521-2.3-9.37-.7172-5.4817-.7172-9.4833-7.2337-10.1975V65.647c4.8771-1.0422,8.2175-4.4438,8.2175-9.5955,0-8.0512-6.9623-10.2442-13.7558-10.3028H255.1528V85.32h8.4945Z"/>
        </svg>

    );
}

export default SvgPfeifferLogoColored ;