import React from 'react';
import './index.css';

export interface Props {
    label: string,
    value: string,
    highlighted?: boolean,
}

const ProductCardFilter = (props: Props) => {

    const {label, value, highlighted} = props;

    return (
        <div className={'product-card-filter-wrapper'}>
            {highlighted ?
                <div className={'product-card-filter highlighted'}>
                    <p className={'product-card-filter-label highlighted'}>{label}:</p>
                    <p className={'product-card-filter-value highlighted'}>{value}</p>
                </div> :
                <div className={'product-card-filter'}>
                    <p className={'product-card-filter-label'}>{label}:</p>
                    <p className={'product-card-filter-value'}>{value}</p>
            </div>}
        </div>
    );
}


export default ProductCardFilter;
