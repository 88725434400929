import React, {ReactNode} from 'react';
import {Empty, Grid} from 'antd';
import './index.css';
import ProductCardLabel from "../ProductCardLabel";

export interface Props {
    children?: ReactNode,
    img?: {
        alt: string,
        src: string,
    },
    defaultImage?: {
        alt: string,
        src: string
    }
    productName: string,
    productCategory: string,
    onClick?: () => void,
    width?: string

}

const ProductCard = (props: Props) => {

    const {img, defaultImage, productCategory, productName, onClick, width} = props;

    const { useBreakpoint } = Grid;
    const {sm, md, lg} = useBreakpoint();
    const matchingBreakpoint = lg ? "lg" : md ? "md" : sm ? "sm" : "xs";

    return (
        <div
            className={`product-card ${onClick ? 'pointer' : ''} ${matchingBreakpoint}`}
            onClick={onClick ? onClick : () => {}}
            style={{width: width ? width : '275px'}}
        >
            <div className={`product-card__top-content ${matchingBreakpoint}`}>
                <span className={'helper'}></span>
                {img ?
                    <img
                        className={`product-card-img ${matchingBreakpoint}`}
                        alt={img.alt}
                        src={img.src}
                    />
                    : defaultImage ?
                        <img
                            className={`product-card-img ${matchingBreakpoint}`}
                            alt={defaultImage.alt}
                            src={defaultImage.src}
                        />
                        :
                        <Empty className={`product-card-img ${matchingBreakpoint}`}/>
                }
            </div>


            <div className={`product-card__bottom-content ${matchingBreakpoint}`}>
                <ProductCardLabel productName={productName} productCategory={productCategory}/>
                {props.children}
            </div>
        </div>
    );
}


export default ProductCard;
