import React from 'react';
import "./index.css"

interface Props {

}

const LoadingSpinner = ({}: Props) => {
    return (
        <div className={"pv-loading-spinner-wrapper"}>
            <div className={"pv-loading-spinner"}></div>
        </div>
    );
}

export default LoadingSpinner;
