// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import Button from "../Button";
import './index.css';

type Props = {
    type?: "primary" | "primary-inverted" | "secondary" | "tertiary" | "tertiary-inverted" | "ghost" | "ghost-inverted",
    onLogin: Function
}

const LoginButton = ({type, onLogin}: Props) => {
    const {t} = useTranslation();

    return <Button
        id={'LoginButton'}
        className={'LoginButton'}
        type={type ? type : 'ghost'}
        onClick={() => onLogin()}
        icon={"person"}
    >
        {t('Sign in')}
    </Button>
};

export default LoginButton
