import * as React from "react"
import MaterialIcon from "../MaterialIcon";

function CarouselArrowRightIcon() {
    return (
        <span
            role={'img'}
            //aria-label={'arrow-right'}
            datatype={'none'}
            style={{
                width:'48px',
                height: '48px',
                backgroundColor: '#DD1541',
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center'
            }}
            className={'slick-next slick-arrow'}
        >
            <MaterialIcon type={"arrow_forward"} style={{fontSize: "24px", color: '#FFFFFF'}} />
        </span>
    )
}

export default CarouselArrowRightIcon
