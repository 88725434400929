import React from "react";
import './index.css'
import Button from '../Button';
import MaterialIcon from "../MaterialIcon";


interface Props {
    heading: string,
    infoBoxText?: string,
    infoBoxButtonLabel: string,
    onClick?: () => void,
    topRightIcon?: string,
    buttonIcon?: string
}

const InfoBox = ({heading, infoBoxText, infoBoxButtonLabel, onClick, topRightIcon, buttonIcon}: Props) => {


    return <div className="infoBox">

        {/*Heading + Icon of the Box*/}
        <div className="infoBoxHeaderCouple">
            <p className="infoBoxHeading">{heading}</p>
            <div className="infoBoxIcon">
                {topRightIcon ? <MaterialIcon type={topRightIcon} style={{fontSize: "52px"}} /> : null}
            </div>
        </div>

        {/*Infobox Text*/}
        <div className="infoBoxText">{infoBoxText}</div>

        {/*Action-Button with Icon*/}
        <Button
            type="tertiary-inverted"
            onClick={onClick}
            icon={buttonIcon}
            style={{width: "100%", margin: "auto"}}
        >
            {infoBoxButtonLabel}
        </Button>

    </div>
};

export default InfoBox;

