import React, {ReactNode} from "react";
import {CheckCircleFilled, InfoCircleFilled} from "@ant-design/icons"
import './index.css';

interface Props {
    title: string,
    subtitle?: string,
    type: 'error' | 'warning' | 'success' | 'info',
    children?: ReactNode
}

const FeedbackBox = ({title, subtitle, type, children}: Props) => {

    return <div className="feedback-box-wrapper">
        <div className={`left-content left-content-${type}`}>
            <div
                className="feedback-title"
                style={{paddingBottom: subtitle ? '8px' : '0'}}
            >
                {title}
            </div>
            {
                subtitle ?
                    <p>{subtitle}</p>
                : null
            }
            {children}
        </div>
        <div className={`right-content right-content-${type}`}>
            {
                type === 'success' ? <CheckCircleFilled /> : <InfoCircleFilled />
            }
        </div>
    </div>
};

export default FeedbackBox;
