import React, {ReactNode, forwardRef} from "react";
import './index.css';
import { Carousel as AntdCarousel } from 'antd';
import CarouselArrowLeftIcon from "../Icons/CarouselArrowLeftIcon";
import CarouselArrowRightIcon from "../Icons/CarouselArrowRightIcon";

export interface Props {
    children?: ReactNode,
    afterChange?: (current: any) => void
    beforeChange?: (from: any, to: any) => void
}

const Carousel = forwardRef(({children, afterChange, beforeChange}: Props, ref: any) => {
    return <AntdCarousel
        arrows={true}
        afterChange={afterChange}
        beforeChange={beforeChange}
        prevArrow={<span><CarouselArrowLeftIcon/></span>}
        nextArrow={<span><CarouselArrowRightIcon/></span>}
        dots={false}
        infinite={false}
        centerMode={false}
        centerPadding={'0px'}
        ref={ref}
    >
        {children}
    </AntdCarousel>
})

export default Carousel
