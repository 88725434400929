// @flow
import React from "react";
import LoginButton from "../LoginButton";
import LogoutButton from "../LogoutButton";

type Props = {
    authenticated?: boolean,
    type?: "primary" | "primary-inverted" | "secondary" | "tertiary" | "tertiary-inverted" | "ghost" | "ghost-inverted",
    onLogin: Function,
    onLogout?: Function
    userName?: string,
}

const UserButton = ({authenticated, type, onLogin, onLogout, userName}: Props) => {

    if(authenticated && userName) return <LogoutButton type={type} onLogout={onLogout} userName={userName}/>;
    return <LoginButton type={type} onLogin={onLogin}/>;
};

export default UserButton
