import React from "react";
import {Pagination as AntdPagination} from "antd"
import './index.css';
import MaterialIcon from "../MaterialIcon";

interface Props {
    pageSize: number,
    total: number,
    onChange?: (page: number, pageSize?: number) => void,
    mobile?: boolean
}

const Pagination = ({pageSize, total, onChange}: Props) => {

    function itemRender(_current: any, type: string, originalElement: any) {
        if (type === 'prev') {
            return <MaterialIcon type={"arrow_back"}/>
        }
        if (type === 'next') {
            return <MaterialIcon type={"arrow_forward"}/>;
        }
        return originalElement;
    }

    return <AntdPagination
        pageSize={pageSize}
        total={total}
        onChange={onChange}
        showSizeChanger={false}
        itemRender={itemRender}
    />
};

export default Pagination;
