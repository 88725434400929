// @flow
import React from "react";
import {useTranslation} from "react-i18next";
import {Menu} from "antd";
import MaterialIcon from "../MaterialIcon";

const {SubMenu} = Menu;

type Props = {
    mode: any,
    onChangeLanguage?: (language: string) => void,
    authenticated?: boolean,
    UserButtonType?: 'default' | 'primary' | 'link',
    onLogin?: Function,
    userName?: string,
    onLogout?: Function,
    className?: string,
    salesEngineer?: {
        name: string,
        phone: string
    },
    onContact?: () => void,
    env?: string | 'testing' | 'development' | 'production'
}

const devWebportalBaseUrl = "https://www.dev.customerportal.pfeiffer-vacuum.com"
const uatWebportalBaseUrl = "https://www.uat.customerportal.pfeiffer-vacuum.com"
const prodWebportalBaseUrl = "https://www.pfeiffer-vacuum.com"

const HeaderMenu = ({className, onChangeLanguage, mode, userName, onLogout, onLogin, authenticated, salesEngineer, onContact, env}: Props) => {
    const {t, i18n} = useTranslation();

    const headerIconStyles = {fontSize: "24px", padding: "0 4px 4px 0", verticalAlign: "middle"}

    let contactURL = `${prodWebportalBaseUrl}/global/${i18n.language}/contact/`;
    if(env && (env === 'testing') || (env === 'development')){
        contactURL = `${devWebportalBaseUrl}/global/${i18n.language}/contact/`
    }
    if(env && env === 'staging'){
        contactURL = `${uatWebportalBaseUrl}/global/${i18n.language}/contact/`
    }

    return <Menu mode={mode}
                 className={className}
                 id={'HeaderRightMenu'}
    >
        {
            salesEngineer ?
                <Menu.Item
                    key="salesEngineer"
                    icon={<MaterialIcon type={"perm_contact_calendar"} style={headerIconStyles} />}
                >
                    <span className={"salesEngineer-name"}>{t('Contact')}: {salesEngineer.name}</span>
                    <span className={"salesEngineer-phone"}> T {salesEngineer.phone}</span>
                </Menu.Item>
                : null
        }

        {
            onChangeLanguage
                ? <SubMenu
                    key="globe"
                    icon={<MaterialIcon type={"language"} style={headerIconStyles} />}
                    title={t(i18n.language)}
                    style={{display: "flex"}}
                >
                    <Menu.Item key="de"
                               onClick={() => {
                                   onChangeLanguage("de")
                               }}
                    >
                        Deutsch
                    </Menu.Item>
                    <Menu.Item key="en"
                               onClick={() => {
                                   onChangeLanguage("en")
                               }}
                    >
                        English
                    </Menu.Item>
                </SubMenu>
                : null
        }

        <Menu.Item
            key="mail"
            icon={<MaterialIcon type={"mail_outline"} style={headerIconStyles} />}
            onClick={() => onContact ? onContact() : window.open(contactURL, '_blank')}
        >
            {t('Contact')}
        </Menu.Item>

        {
            onLogout && userName && authenticated ?
                <SubMenu
                    key="userActions"
                    icon={<MaterialIcon type={"person"} style={headerIconStyles} />}
                    title={userName}
                    className="userMenuItem"
                >
                    <Menu.Item onClick={() => {
                        onLogout()
                        className="userMenuItem"
                    }}>
                        <MaterialIcon type={"logout"} style={headerIconStyles} />
                        {t('Logout')}
                    </Menu.Item>
                </SubMenu>
            : onLogin ?
                    <Menu.Item
                        key="userActions"
                        icon={<MaterialIcon type={"person"} style={headerIconStyles} />}
                        onClick={() => {
                            onLogin()
                        }}
                        className="userMenuItem"
                    >
                        {t('Login')}
                    </Menu.Item>
                : null
        }

    </Menu>;
};

export default HeaderMenu
