import React, {ReactNode} from "react";
import './index.css';
import {Row, Spin} from "antd";
import {LoadingOutlined, CloseOutlined} from "@ant-design/icons"
import ListIcon from "../Icons/ListIcon";
import SaveIcon from "../Icons/SaveIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import DoneIcon from "../Icons/DoneIcon";


export interface Props {
    children?: ReactNode,
    onSaveCard?: () => void,
    onDeleteCard?: () => void,
    onList?: () => void,
    listDisabled?: boolean,
    saveState?: "ready" | "loading" | "done" | "error" | "disabled",
    deletable?: boolean,
    title: string,
}

const defaultProps = {
    saveState: "ready",
    deletable: false,
}

const CarouselCard = (props: Props) => {
    const {onSaveCard, onList, onDeleteCard, deletable, title, saveState, listDisabled} = props;

    return (
        <div className={'carousel-component-slide'}>
            <div className={'carousel-item-wrapper'}>
                <Row className={'carousel-item-header'}>
                    {title}
                    <div className={'carousel-header-extras'}>
                        {
                            listDisabled ?
                                <ListIcon className={'carousel-item-header-icon disabled'}/>
                                : <ListIcon onClick={onList} className={'carousel-item-header-icon'}/>
                        }
                        {
                            saveState === "ready" ?
                                <SaveIcon onClick={onSaveCard} className={'carousel-item-header-icon'}/>
                            : saveState === "loading" ?
                                <Spin indicator={<LoadingOutlined  className={'carousel-item-header-icon'} spin />} />
                            : saveState === "error" ?
                                <CloseOutlined className={'carousel-item-header-icon error-icon'} />
                            : saveState === "done" ?
                                <DoneIcon className={'carousel-item-header-icon'}/>
                            : saveState === "disabled" ?
                                <SaveIcon className={'carousel-item-header-icon disabled'}/>
                            : null
                        }
                        {deletable ? <DeleteIcon onClick={onDeleteCard} className={'carousel-item-header-icon'}/> : <DeleteIcon className={'carousel-item-header-icon disabled'}/>}
                    </div>
                </Row>
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
CarouselCard.defaultProps = defaultProps;

export default CarouselCard
